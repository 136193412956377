(function () {
  'use strict';

  /* @ngdoc object
   * @name home.referees
   * @description
   *
   */
  angular
    .module('neo.home.referees', [
      'neo.home.referees.newReferee',
      'neo.home.referees.referee'
    ]);
}());
